// frontend/src/components/AccountList.js
import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosInstance';
import { Card, CardContent, Typography, Grid, Box, Divider, TextField, CircularProgress } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, Legend } from 'recharts';
import moment from 'moment'; // 引入 moment.js 用于日期处理

const AccountList = () => {
  const [accounts, setAccounts] = useState([]); // 存储子账户信息
  const [spendHistoryData, setSpendHistoryData] = useState({}); // 存储消费历史数据
  const [searchTerm, setSearchTerm] = useState(''); // 搜索框的输入值
  const [loading, setLoading] = useState(true); // 加载状态

  // 获取子账户基本信息
  useEffect(() => {
    const token = localStorage.getItem('token'); // 从本地存储获取用户令牌
    axiosInstance.get('/api/accounts/basic_info', { headers: { Authorization: token } })
      .then(response => {
        setAccounts(response.data); // 设置子账户信息
      })
      .catch(error => {
        console.error("获取账户基本信息失败: ", error); // 处理错误
      })
      .finally(() => {
        setLoading(false); // 设置加载状态为 false
      });
  }, []);

  // 获取历史消费数据
  useEffect(() => {
    const token = localStorage.getItem('token'); // 从本地存储获取用户令牌
    axiosInstance.get('/api/accounts/data_from_db', { headers: { Authorization: token } })
      .then(response => {
        // 处理消费历史数据，将其整理成特定格式
        const historyData = response.data.reduce((acc, record) => {
          if (!acc[record.account_user_name]) {
            acc[record.account_user_name] = [];
          }
          acc[record.account_user_name].push({
            date: record.date,
            spend: record.spend,
            leads: record.leads,
            leadCost: record.leadCost
          });
          return acc;
        }, {});
        setSpendHistoryData(historyData); // 设置消费历史数据
      })
      .catch(error => {
        console.error("获取历史消费数据失败: ", error); // 处理错误
      });
  }, []);

  // 搜索框输入处理函数
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value); // 更新搜索框的输入值
  };

  // 获取今天的日期，并计算七天前的日期（原来是两周前）
  const today = moment().startOf('day');
  const sevenDaysAgo = moment().subtract(7, 'days').startOf('day');

  // 根据搜索框输入值过滤账户列表，并按当日消费降序排序
  const filteredAccounts = accounts
    .filter(account =>
      account.account_user_name && account.account_user_name.toLowerCase().includes(searchTerm.toLowerCase()) // 确保 account_user_name 存在
    )
    .sort((a, b) => b.dailySpend - a.dailySpend); // 按 dailySpend 降序排序

  // 如果正在加载，显示加载指示器
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress /> {/* 加载中状态 */}
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" align="center" gutterBottom>账户总览</Typography>
      
      {/* 搜索框 */}
      <TextField
        label="搜索账户"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={handleSearchChange} // 绑定输入处理函数
        sx={{ mb: 3 }}
      />

      {/* 子账户信息卡片展示 */}
      <Grid container spacing={2}>
        {filteredAccounts.map((account, index) => {
          // 从消费历史数据中获取对应子账户的数据
          let historyData = spendHistoryData[account.account_user_name] || [];

          // 过滤只保留从七天前到昨天的数据（原来是两周前）
          historyData = historyData.filter(record => {
            const recordDate = moment(record.date, 'YYYY-MM-DD');
            return recordDate.isBetween(sevenDaysAgo, today, null, '[]') && recordDate.isBefore(today);
          });

          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  minWidth: 275,
                  borderRadius: 2,
                  boxShadow: 3,
                  p: 2,
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: 6,
                  }
                }}
              >
                <CardContent>
                  <Typography variant="h6" align="left" gutterBottom>{account.account_user_name}</Typography>
                  <Divider sx={{ my: 1 }} />
                  
                  {/* 余额信息 */}
                  <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold', mt: 1 }}>余额:</Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      mb: 1,
                      color: account.balance < 1000 ? 'error.main' : 'primary.main'
                    }}
                  >
                    ¥{account.balance}
                  </Typography>

                  <Divider sx={{ my: 1 }} />
                  
                  {/* 当日消费金额 */}
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="body2" color="textSecondary">当日消费金额:</Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        mb: 1,
                        color: account.dailySpend > 5000 ? 'warning.main' : 'text.primary'
                      }}
                    >
                      ¥{account.dailySpend}
                    </Typography>
                  </Box>

                  {/* 当日留线索数量 */}
                  <Box>
                    <Typography variant="body2" color="textSecondary">当日留线索数量:</Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>{account.dailyLeads}</Typography>
                  </Box>

                  {/* 线索单价 */}
                  <Box>
                    <Typography variant="body2" color="textSecondary">线索单价:</Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: account.leadCost === 'N/A' ? 'text.secondary' : 'success.main'
                      }}
                    >
                      ¥{account.leadCost}
                    </Typography>
                  </Box>

                  {/* 消费趋势图 */}
                  <Box sx={{ mt: 3 }}>
                    <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>消费趋势图:</Typography>
                    {historyData.length > 0 ? (
                      <ResponsiveContainer width="100%" height={200}>
                        <LineChart data={historyData}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="date" />
                          <YAxis />
                          <Tooltip />
                          <Line type="monotone" dataKey="spend" stroke="#8884d8" />
                        </LineChart>
                      </ResponsiveContainer>
                    ) : (
                      <Typography variant="body2" color="textSecondary">暂无消费数据</Typography>
                    )}
                  </Box>

                  {/* 线索数量与单价对比直方图 */}
                  <Box sx={{ mt: 3 }}>
                    <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>线索数量与单价对比:</Typography>
                    {historyData.length > 0 ? (
                      <ResponsiveContainer width="100%" height={200}>
                        <BarChart data={historyData}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="date" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar dataKey="leads" fill="#82ca9d" name="线索数量" />
                          <Bar dataKey="leadCost" fill="#8884d8" name="单价" />
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <Typography variant="body2" color="textSecondary">暂无线索数据</Typography>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default AccountList;
