// src/components/Register.js
import React, { useState } from 'react';
import axiosInstance from '../axiosInstance';
import { 
  TextField, 
  Button, 
  Box, 
  Typography, 
  Container, 
  Snackbar, 
  Alert,
  IconButton,
  InputAdornment
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const Register = ({ onRegisterSuccess }) => {
  // 表单数据状态
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    confirmPassword: ''
  });

  // 错误信息状态
  const [errors, setErrors] = useState({
    username: '',
    password: '',
    confirmPassword: ''
  });

  // UI 状态
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // 处理输入变化
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // 清除对应的错误信息
    setErrors(prev => ({
      ...prev,
      [name]: ''
    }));
  };

  // 验证表单
  const validateForm = () => {
    const newErrors = {};
    
    // 验证用户名
    if (!formData.username) {
      newErrors.username = '请输入用户名';
    } else if (formData.username.length < 3) {
      newErrors.username = '用户名至少需要3个字符';
    }

    // 验证密码
    if (!formData.password) {
      newErrors.password = '请输入密码';
    } else if (formData.password.length < 6) {
      newErrors.password = '密码至少需要6个字符';
    }

    // 验证确认密码
    if (!formData.confirmPassword) {
      newErrors.confirmPassword = '请确认密码';
    } else if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = '两次输入的密码不一致';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // 处理表单提交
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await axiosInstance.post('/register', {
        username: formData.username.trim(),
        password: formData.password
      });

      setSnackbar({
        open: true,
        message: response.data.message || '注册成功',
        severity: 'success'
      });

      // 清空表单
      setFormData({
        username: '',
        password: '',
        confirmPassword: ''
      });

      // 延迟执行注册成功回调，给用户时间看到成功消息
      setTimeout(() => {
        onRegisterSuccess();
      }, 1500);

    } catch (error) {
      const errorMsg = error.response?.data?.message || '注册失败，请稍后重试';
      setSnackbar({
        open: true,
        message: errorMsg,
        severity: 'error'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // 处理 Snackbar 关闭
  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({
      ...prev,
      open: false
    }));
  };

  return (
    <Container maxWidth="xs">
      <Box 
        component="form" 
        onSubmit={handleSubmit} 
        sx={{
          mt: 5,
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <Typography 
          variant="h5" 
          align="center" 
          gutterBottom 
          sx={{ mb: 3 }}
        >
          注册新账号
        </Typography>

        {/* 用户名输入框 */}
        <TextField 
          name="username"
          label="用户名" 
          variant="outlined" 
          fullWidth
          value={formData.username}
          onChange={handleChange}
          error={!!errors.username}
          helperText={errors.username}
          required
          inputProps={{
            minLength: 3,
            maxLength: 20
          }}
        />

        {/* 密码输入框 */}
        <TextField 
          name="password"
          label="密码" 
          type={showPassword ? 'text' : 'password'}
          variant="outlined" 
          fullWidth
          value={formData.password}
          onChange={handleChange}
          error={!!errors.password}
          helperText={errors.password}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />

        {/* 确认密码输入框 */}
        <TextField 
          name="confirmPassword"
          label="确认密码" 
          type={showConfirmPassword ? 'text' : 'password'}
          variant="outlined" 
          fullWidth
          value={formData.confirmPassword}
          onChange={handleChange}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />

        {/* 注册按钮 */}
        <Button 
          type="submit"
          fullWidth 
          variant="contained" 
          color="primary"
          disabled={isSubmitting}
          sx={{ 
            mt: 2,
            height: 48,
            fontSize: '1.1rem'
          }}
        >
          {isSubmitting ? '注册中...' : '注册'}
        </Button>
      </Box>

      {/* 提示消息 */}
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={3000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity} 
          elevation={6}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Register;