// src/components/Login.js
import React, { useState } from 'react';
import axiosInstance from '../axiosInstance';
import { TextField, Button, Box, Typography, Container, Snackbar, Alert, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const Login = ({ onLoginSuccess }) => {
  // 状态管理
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });
  const [errors, setErrors] = useState({
    username: '',
    password: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // 处理输入字段变化 - 不再进行trim处理
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    // 清除相应的错误信息
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  // 表单验证 - 仅在提交时进行trim检查
  const validateForm = () => {
    const newErrors = {};
    
    // 检查用户名（提交时才trim）
    if (!formData.username.trim()) {
      newErrors.username = '用户名不能为空';
    }
    
    // 检查密码
    if (!formData.password) {
      newErrors.password = '密码不能为空';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // 处理登录表单提交
  const handleSubmit = async (e) => {
    e.preventDefault();

    // 在提交时进行表单验证
    if (!validateForm()) {
      return;
    }

    try {
      // 仅在提交时trim用户名
      const trimmedUsername = formData.username.trim();
      
      const response = await axiosInstance.post('/login', {
        username: trimmedUsername,
        password: formData.password
      });
      
      // 存储令牌和用户名（使用trim后的用户名）
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('username', trimmedUsername);
      
      // 显示成功消息
      setSnackbarState({
        open: true,
        message: '登录成功',
        severity: 'success'
      });
      
      // 调用父组件的登录成功回调
      onLoginSuccess({
        username: trimmedUsername,
        ...response.data
      });
    } catch (error) {
      const errorMessage = error.response?.data?.message || '网络错误';
      setSnackbarState({
        open: true,
        message: '登录失败：' + errorMessage,
        severity: 'error'
      });
      
      localStorage.removeItem('token');
      localStorage.removeItem('username');
    }
  };

  // 处理密码可见性切换
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // 处理Snackbar关闭
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarState(prev => ({
      ...prev,
      open: false
    }));
  };

  return (
    <Container maxWidth="xs">
      <Box 
        component="form" 
        onSubmit={handleSubmit} 
        sx={{
          mt: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        {/* 标题 */}
        <Typography 
          variant="h5" 
          align="center" 
          gutterBottom
          sx={{ mb: 3 }}
        >
          登录
        </Typography>

        {/* 用户名输入框 - 移除onChange中的trim */}
        <TextField 
          name="username"
          label="用户名" 
          variant="outlined" 
          fullWidth 
          margin="normal" 
          value={formData.username}
          onChange={handleInputChange}
          error={!!errors.username}
          helperText={errors.username}
          required
          autoFocus
          inputProps={{
            maxLength: 20,
            autoComplete: 'username',
            // 添加移动端输入优化
            inputMode: 'text',
            'aria-label': '用户名'
          }}
          // 添加移动端样式优化
          sx={{
            '& .MuiInputBase-input': {
              fontSize: '16px', // 防止iOS缩放
              webkitTextFillColor: 'currentColor', // 修复iOS输入问题
            }
          }}
        />

        {/* 密码输入框 */}
        <TextField 
          name="password"
          label="密码" 
          variant="outlined" 
          type={showPassword ? 'text' : 'password'}
          fullWidth 
          margin="normal" 
          value={formData.password}
          onChange={handleInputChange}
          error={!!errors.password}
          helperText={errors.password}
          required
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={handleTogglePasswordVisibility}
                edge="end"
                size="large"
                sx={{ padding: '8px' }} // 增大点击区域
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
          inputProps={{
            maxLength: 50,
            autoComplete: 'current-password',
            // 添加移动端输入优化
            'aria-label': '密码'
          }}
          // 添加移动端样式优化
          sx={{
            '& .MuiInputBase-input': {
              fontSize: '16px', // 防止iOS缩放
            }
          }}
        />

        {/* 登录按钮 - 优化移动端点击 */}
        <Button 
          type="submit" 
          fullWidth 
          variant="contained" 
          color="primary" 
          sx={{ 
            mt: 3,
            mb: 2,
            height: 48,
            fontSize: '1.1rem',
            touchAction: 'manipulation', // 优化移动端触摸
            '@media (max-width: 600px)': {
              height: 56, // 移动端更大的点击区域
            }
          }}
        >
          登录
        </Button>
      </Box>

      {/* 提示消息Snackbar */}
      <Snackbar 
        open={snackbarState.open} 
        autoHideDuration={3000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          // 优化移动端位置
          '@media (max-width: 600px)': {
            top: '24px !important'
          }
        }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbarState.severity} 
          elevation={6}
          variant="filled" 
          sx={{ 
            width: '100%',
            // 优化移动端显示
            '@media (max-width: 600px)': {
              width: 'auto',
              margin: '0 16px'
            }
          }}
        >
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Login;