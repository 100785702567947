// src/components/SetMccInfo.js
import React, { useState } from 'react';
import axiosInstance from '../axiosInstance';
import {
  TextField,
  Button,
  Box,
  Typography,
  Container,
  Snackbar,
  Alert,
  Paper,
  IconButton,
  CircularProgress,
  Tooltip
} from '@mui/material';
import { Help, ContentCopy } from '@mui/icons-material';

const SetMccInfo = () => {
  // 表单数据状态
  const [formData, setFormData] = useState({
    mccUserName: '',
    accessToken: ''
  });

  // 表单错误状态
  const [errors, setErrors] = useState({
    mccUserName: '',
    accessToken: ''
  });

  // UI 状态
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // 处理表单输入变化
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    // 清除对应的错误信息
    setErrors(prev => ({
      ...prev,
      [name]: ''
    }));
  };

  // 验证表单数据
  const validateForm = () => {
    const newErrors = {};

    // 验证管家账户名
    if (!formData.mccUserName) {
      newErrors.mccUserName = '请输入管家账户名';
    } else if (formData.mccUserName.length < 2) {
      newErrors.mccUserName = '账户名长度不正确';
    }

    // 验证 Access Token
    if (!formData.accessToken) {
      newErrors.accessToken = '请输入Access Token';
    } else if (formData.accessToken.length < 32) {
      newErrors.accessToken = 'Access Token格式不正确';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // 处理表单提交
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    const token = localStorage.getItem('token');

    try {
      const response = await axiosInstance.post(
        '/add_mcc_account',
        {
          mcc_user_name: formData.mccUserName.trim(),
          access_token: formData.accessToken.trim()
        },
        { headers: { Authorization: token } }
      );

      // 成功处理
      setSnackbar({
        open: true,
        message: response.data.message || '设置成功',
        severity: 'success'
      });

      // 清空表单
      setFormData({
        mccUserName: '',
        accessToken: ''
      });

    } catch (error) {
      // 错误处理
      const errorMsg = error.response?.data?.message || '网络错误，请稍后重试';
      setSnackbar({
        open: true,
        message: '设置失败：' + errorMsg,
        severity: 'error'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // 处理提示消息关闭
  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  // 复制到剪贴板
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setSnackbar({
        open: true,
        message: '复制成功',
        severity: 'success'
      });
    });
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 4, mt: 5 }}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3
          }}
        >
          {/* 标题 */}
          <Typography 
            variant="h5" 
            align="center" 
            gutterBottom
            sx={{ 
              fontWeight: 500,
              color: 'primary.main' 
            }}
          >
            设置管家账户信息
          </Typography>

          {/* 管家账户名输入框 */}
          <TextField
            name="mccUserName"
            label="管家账户名"
            variant="outlined"
            fullWidth
            value={formData.mccUserName}
            onChange={handleChange}
            error={!!errors.mccUserName}
            helperText={errors.mccUserName}
            required
            InputProps={{
              endAdornment: (
                <Tooltip title="百度管家平台的账户用户名">
                  <IconButton size="small">
                    <Help fontSize="small" />
                  </IconButton>
                </Tooltip>
              )
            }}
          />

          {/* Access Token 输入框 */}
          <TextField
            name="accessToken"
            label="Access Token"
            variant="outlined"
            fullWidth
            value={formData.accessToken}
            onChange={handleChange}
            error={!!errors.accessToken}
            helperText={errors.accessToken || '请在百度管家平台获取Access Token'}
            required
            InputProps={{
              endAdornment: formData.accessToken && (
                <Tooltip title="复制Token">
                  <IconButton 
                    onClick={() => handleCopy(formData.accessToken)}
                    size="small"
                  >
                    <ContentCopy fontSize="small" />
                  </IconButton>
                </Tooltip>
              )
            }}
          />

          {/* 提交按钮 */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            sx={{
              height: 48,
              fontSize: '1.1rem',
              mt: 2
            }}
          >
            {isSubmitting ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              '保存设置'
            )}
          </Button>
        </Box>
      </Paper>

      {/* 帮助信息 */}
      <Paper 
        elevation={1} 
        sx={{ 
          p: 2, 
          mt: 3,
          bgcolor: 'info.main',
          color: 'white'
        }}
      >
        <Typography variant="body2">
          提示：Access Token可以在百度营销平台的API设置中获取。
          请确保Token的正确性，错误的Token将无法获取数据。
        </Typography>
      </Paper>

      {/* 提示消息 */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          elevation={6}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SetMccInfo;